import React from 'react';
import styled from 'styled-components';
// import Header from './components/Header';
// import AbsoluteFooter from './components/AbsoluteFooter';
import ContractAddress from './components/ContractAddress';
import Mint from './components/Mint';

 const ContainerRoot = styled.div`
    min-height: 100vh;
    width: 100vw;
    // background-image: url(https://ipfs.io/ipfs/QmYjkpKnKDxErXEax2G9BGrHc31eaChLKD8UQiCrKtbz4d);
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
`

const App = ()  => (
    <ContainerRoot>
      <Mint/>
      <ContractAddress/>
  </ContainerRoot>
  );

 export default App;