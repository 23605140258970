import React from 'react';
import { ethers } from "ethers";
import styled from 'styled-components';

const StyledSpan = styled.span`
    font-family: montserrat;
    color: magenta;
    font-size: 12px;
    border-radius: 5px;
    margin: 10px 0px;
    padding: 10px 60px;
    text-align: center;
    justify-content: center;
    position: center;
    border-radius: 5px;
    border-color: magenta;
    border-width: 10px;
    max-width: 70vw;
`
const StyledSuccess = styled.div`
    overflow: hidden;
    max-width: 60vw;
`
const StyledMsg = styled.p`
    font-family: montserrat;
    color: magenta;
    font-size: 12px;
    border-radius: 10px;
    border-width: 10px;
    border-color: magenta;
    margin: 10px 0px;
    padding: 10px 60px;
    text-align: center;
    justify-content: center;
    border-radius: 5px;
    max-width: 50vw;
    word-wrap: wrap;
    overflow: hidden;
    @media (max-width: 568px) {
      text-align: left;
    }
`

const StyledLink = styled.p`
  font-family: montserrat;
    color: magenta;
    font-size: 12px;
    border-radius: 10px;
    border-width: 10px;
    border-color: magenta;
    margin: 10px 0px;
    padding: 10px 60px;
    text-align: center;
    justify-content: center;
    border-radius: 5px;
    max-width: 50vw;
    word-wrap: wrap;
    overflow: hidden;
    hover: pointer;
    @media (max-width: 568px) {
      hidden: true;
      display: none;
    }
`

const errormsg = "Sorry, you have encountered an error. Please ensure your wallet is connected, you have sufficient funds, you are connected to the Ethereum Mainnet network, you have selected a number of Spirit Seeds, you pressed the “Mint Spirit Seed” button and your wallet is not awaiting a confirmation to proceed. Refreshing this page or restarting your browser may also be of use. If you are still having issues copy the error message below and send it to contact@edgeofnft.com and if possible, we will follow up to correct the problem.";
const contractABI = require("../contract-abi.json");

export const connectWallet = async () => {
  if (window.ethereum) 
  {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: (
          <StyledSpan>
            <StyledMsg>Select a seed amount</StyledMsg>
          </StyledSpan>
          ),
        address: addressArray[0],
      };
      return obj;
    } catch (error) {
      return {
        address: "",
        status: "😥 " + error.message,
      };
    }
  } else {
    alert("Note: No Ethereum wallet detected. If you are on mobile, you will need to open this website within your Metamask browser. When opening on a computer, your Ethereum wallet will be a browser extension, or a Web3 Browser like Brave Browser.");
    return {
      address: "",
      status: (
        <StyledSpan onClick={() => {window.open("https://metamask.io/download.html");}}>
          <StyledMsg> {" "} 🦊 No Ethereum wallet detected click here to install metamask {" "} </StyledMsg>
          <StyledMsg> {" "} 🦊 If on mobile, and Metamask is already installed, please open this site in Metamask Browser {" "} </StyledMsg>
        </StyledSpan>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) 
  {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: (
            <StyledSpan>
              <StyledMsg>Select A Seed Amount</StyledMsg>
            </StyledSpan>
            ),
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top button.",
        };
      }
    } catch (error) {
      console.log('err.value :>> ', error.value);
      if(error.code === 4001)
      {
        return {
          address: "",
          status: (
            <StyledSpan>
              {/* <StyledMsg>Error: {errormsg}</StyledMsg> */}
              <StyledMsg>{error.message}</StyledMsg>
            </StyledSpan>
          )
        };
      }
      return {
        address: "",
        status: (
          <StyledSpan>
            {/* <StyledMsg>Error: {errormsg}</StyledMsg> */}
            <StyledMsg>{error.message}</StyledMsg>
          </StyledSpan>
        )
      };
    }
  } 
  else 
  {
    return {
      address: "",
      status: (
        <StyledSpan onClick={() => {window.open("https://metamask.io/download.html");}}>
          <StyledMsg> {" "} 🦊 No Ethereum wallet detected click here to install metamask {" "} </StyledMsg>
          <StyledMsg> {" "} 🦊 If on mobile, and Metamask is already installed, please open this site in Metamask Browser {" "} </StyledMsg>
        </StyledSpan>
      ),
    };
  }
};

export const mintNFT = async (tokenAmount) => {
  if (!tokenAmount) 
  {
    if(!window.ethereum)
    {
      return{
        success: false,
        status: (
          <StyledSpan onClick={() => {window.open("https://metamask.io/download.html");}}>
            <StyledMsg> {" "} 🦊 No Ethereum wallet detected click here to install Metamask {" "} </StyledMsg>
            <StyledMsg> {" "} 🦊 If on mobile, and Metamask is already installed, please open this site in Metamask Browser {" "} </StyledMsg>
          </StyledSpan>
        ),
      }
    }
    return {
      success: false,
      status: (
          <StyledSpan>
            <p> {" "} ❗ Please click a seed amount to continue minting ❗</p>
          </StyledSpan>
        ),
    };
  }
  else
  {
    if(!window.ethereum)
    {
      return{
        success: false,
        status: (
          <StyledSpan onClick={() => {window.open("https://metamask.io/download.html");}}>
            <p> {" "} 🦊 No Ethereum wallet detected click here to install Metamask {" "} </p>
          </StyledSpan>
        ),
      }
    }
    else
    {
      let etherAmount = "0.0";
  switch(tokenAmount) 
  {
    case "1":
      etherAmount = "0.55";
      break;
    case "2":
      etherAmount = "1.1";
      break;
    case "3":
      etherAmount = "1.65";
      break;
    case "4":
      etherAmount = "2.2";
      break;
    case "5":
      etherAmount = "2.75";
      break;
    default:
      etherAmount = "0.0";
      break;
  };
  etherAmount = ethers.utils.parseEther(etherAmount);
  try {
    const contractAddress = "0x9B07bb421dF4e2D52d5997DA54A99CFf33D5ed6B";
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const SpiritSeed = new ethers.Contract(contractAddress, contractABI, signer);
    const { chainId } = await provider.getNetwork()
    if(chainId !== 1)
    {
      return {
        success: false,
        status: (
          <StyledSpan>
            <p><span role="img" aria-label="submit">🌐</span> Please switch to Ethereum Mainnet</p>
          </StyledSpan>
        ),
      };
    }
    else
    {
      let tx = await SpiritSeed.SpiritSeedMint(tokenAmount, { value: etherAmount });
      let txhash = tx.hash;
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const walletAddressConnected = addressArray[0].toString();
      const txnURL = `https://etherscan.io/tx/${txhash}`;
      const openseaURL = `https://opensea.io/${walletAddressConnected}`;
      return {
        success: true,
        status: (
          <StyledSuccess>
            <StyledSpan>
              <StyledMsg><span role="img" aria-label="submit">✅</span> Transaction submitted</StyledMsg>
              <StyledMsg onClick={() => {window.open(txnURL);}}>Click to view your transaction on Etherscan</StyledMsg>
              <StyledLink onClick={() => {window.open(txnURL);}}>{txnURL}</StyledLink>
              <StyledMsg onClick={() => {window.open(openseaURL);}}>Click to view your wallet on Opensea</StyledMsg>
              <StyledLink onClick={() => {window.open(openseaURL);}}>{openseaURL}</StyledLink>
            </StyledSpan>
          </StyledSuccess>
        ),
      };
    }
  } catch (error) {
    // console.log('error :>> ', error);
    // console.log('error.code :>> ', error.code);
    if(error.code === 4001)
    {
      // console.log('Error 4001:>> ', error.code);
      return {
        address: "",
        status: (
          <StyledSpan color="red">
          <StyledMsg><span role="img" aria-label="select">😥</span> Error: {error.message}</StyledMsg>
          </StyledSpan>
        )
      };
    }
    else if(error.code === "UNSUPPORTED_OPERATION")
    {
      return {
        address: "",
        status: (
          <StyledSpan color="red">
            <StyledMsg><span role="img" aria-label="select">😥</span> Please connect your wallet to continue</StyledMsg>
          </StyledSpan>
        )
      };
    }
    else if(error.code === "INSUFFICIENT_FUNDS")
    {
      return {
        address: "",
        status: (
          <StyledSpan color="red">
            <StyledMsg><span role="img" aria-label="select">😥</span> Insufficient funds. One seed = 0.55 ETH</StyledMsg>
          </StyledSpan>
        )
      };
    }
    else if(error.code === "UNPREDICTABLE_GAS_LIMIT")
    {
      return {
        address: "",
        status: (
          <StyledSpan color="red">
            <StyledMsg><span role="img" aria-label="select">😥</span> Sale Must Be Active</StyledMsg>
          </StyledSpan>
        )
      };
    }
    else
    {
      return {
        success: false,
        status: (
          <StyledSpan color="red">
            <StyledMsg><span role="img" aria-label="select">😥</span> Error: {errormsg}</StyledMsg>
            <StyledMsg>{error.message}</StyledMsg>
          </StyledSpan>
          ),
        };
      }
    }
  }
}
};