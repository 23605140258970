import React, { useEffect, useState } from 'react';
import { connectWallet, getCurrentWalletConnected, mintNFT, } from "../util/interact.js";
import styled from 'styled-components';

const ContainerRoot = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const MinterRoot = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -150px;
    @media (max-width: 568px) {
      margin-top: -200px;
    }
`
const ButtonRootContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    gap: 10px;
`
const StyledHeader = styled.h1`
    font-family: montserrat;
    color: magenta;
    font-size: 20px;
    border-radius: 5px;
    margin: 10px 0px;
    padding: 10px 60px;
`
const ButtonContainer = styled.button`
    font-family: montserrat;
    background-color: black;
    color: magenta;
    font-size: 20px;
    border-radius: 5px;
    border-color: magenta;
    margin: 10px 0px;
    cursor: pointer;
    padding: 10px 60px;
    margin-bottom: 30px;
`
const ButtonMintContainer = styled.button`
    font-family: montserrat;
    background-color: black;
    color: magenta;
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 30px;
    padding: 10px 60px;
    border-radius: 5px;
    border-color: magenta;
    margin: 10px 0px;
`
const Heading = styled.h1`
    font-family: montserrat;
    color: magenta;
    font-size: 40px;
    // margin: 10px 0px;
    padding: 10px 60px;
    margin-top: 35vh;
`
const Status = styled.h1`
    font-family: montserrat;
    color: magenta;
    font-size: 13px;
    margin: 10px 0px;
    cursor: pointer;
    padding: 10px 60px;
    border-radius: 5px;
    margin: 10px 0px;
`

const Minter = () => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [tokenAmount, setTokenAmount] = useState();

  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);

    addWalletListener();
  }, []);

  function addWalletListener() {
    if (window.ethereum) 
    {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) 
        {
          setWallet(accounts[0]);
          setStatus(
            <StyledHeader><span role="img" aria-label="select">👆🏽</span>Select a seed amount</StyledHeader>);
        } 
        else 
        {
          setWallet("");
          setStatus(
            <StyledHeader><span role="img" aria-label="metamask">🦊</span>Connect your Metamask wallet to continue </StyledHeader>
          );
        }
      });
    } 
    else 
    {
      setStatus(
        <StyledHeader><span role="img" aria-label="metamask">🦊</span>Connect your Metamask wallet to continue</StyledHeader>
      );
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async () => {
    const { success, status } = await mintNFT(tokenAmount);
    setStatus(status);
    if (success) { setTokenAmount(tokenAmount); }
  };

  const handleClick = (tokenAmount) => {
    switch(tokenAmount) 
    {
    case 1:
      setTokenAmount("1");
      document.getElementById("btn1").style.backgroundColor = "white";
      document.getElementById("btn2").style.backgroundColor = "black";
      document.getElementById("btn3").style.backgroundColor = "black";
      document.getElementById("btn4").style.backgroundColor = "black";
      document.getElementById("btn5").style.backgroundColor = "black";
      break;
    case 2:
      setTokenAmount("2");
      document.getElementById("btn1").style.backgroundColor = "black";
      document.getElementById("btn2").style.backgroundColor = "white";
      document.getElementById("btn3").style.backgroundColor = "black";
      document.getElementById("btn4").style.backgroundColor = "black";
      document.getElementById("btn5").style.backgroundColor = "black";
      break;
    case 3:
      setTokenAmount("3");
      document.getElementById("btn1").style.backgroundColor = "black";
      document.getElementById("btn2").style.backgroundColor = "black";
      document.getElementById("btn3").style.backgroundColor = "white";
      document.getElementById("btn4").style.backgroundColor = "black";
      document.getElementById("btn5").style.backgroundColor = "black";
      break;
    case 4:
      setTokenAmount("4");
      document.getElementById("btn1").style.backgroundColor = "black";
      document.getElementById("btn2").style.backgroundColor = "black";
      document.getElementById("btn3").style.backgroundColor = "black";
      document.getElementById("btn4").style.backgroundColor = "white";
      document.getElementById("btn5").style.backgroundColor = "black";
      break;
    case 5:
      setTokenAmount("5");
      document.getElementById("btn1").style.backgroundColor = "black";
      document.getElementById("btn2").style.backgroundColor = "black";
      document.getElementById("btn3").style.backgroundColor = "black";
      document.getElementById("btn4").style.backgroundColor = "black";
      document.getElementById("btn5").style.backgroundColor = "white";
      break;
    default:
      setTokenAmount("3");
    };
  }

  return (
    <ContainerRoot id="place-to-scroll">
      <MinterRoot>
        <Heading>
          {" "}Spirit Seed Mint{" "}
          </Heading>

        <ButtonContainer id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 4) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <StyledHeader>Connect Wallet</StyledHeader>
        )}
        </ButtonContainer>
        <br/>
          <ButtonRootContainer>
            <ButtonContainer id="btn1" onClick={() => handleClick(1)}>1 Seed</ButtonContainer>
            <ButtonContainer id="btn2" onClick={() => handleClick(2)}>2 Seeds</ButtonContainer>
            <ButtonContainer id="btn3" onClick={() => handleClick(3)}>3 Seeds</ButtonContainer>
            <ButtonContainer id="btn4" onClick={() => handleClick(4)}>4 Seeds</ButtonContainer>
            <ButtonContainer id="btn5" onClick={() => handleClick(5)}>5 Seeds</ButtonContainer>
          </ButtonRootContainer>
        <ButtonMintContainer onClick={onMintPressed}>Mint Spirit Seed {" "}<span role="img" aria-label="seed">🌱</span></ButtonMintContainer>
        <Status id="status" style={{ color: "magenta"}}>{status}</Status>
        </MinterRoot>
    </ContainerRoot>
  );
};

export default Minter;