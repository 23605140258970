import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
    padding: 0 20px;
    height: 70px;
    margin-top: 35px;
`

const Desc = styled.h1`
    color: magenta;
    font-family: montserrat;
    font-size: 2vmin;
    padding: 25;
    text-shadow: 5px 5px #000000, 0 0 5px #000000;
    cursor: pointer;
    text-align: center;
`

const _contractAddress = "0x9B07bb421dF4e2D52d5997DA54A99CFf33D5ed6B";

const ContractAddress = () => {
    return (
        <FooterContainer>
            <Desc onClick={() => (window.location.href = 'https://etherscan.io/address/0x9B07bb421dF4e2D52d5997DA54A99CFf33D5ed6B')}>Contract Address: {_contractAddress}</Desc>
        </FooterContainer>
    );
};

export default ContractAddress;